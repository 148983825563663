

body {
  font-family: 'Roboto', sans-serif!important;
}
html{
  overflow-x: hidden;
}
 .navbar-white {
      background-color:white;
    }
body{
  background-color: #E5E5E5;
}
.responsive{
  width: 100%;

}
.relative{
position: relative;
  
}
.absolute{
  position: absolute!important;
}
.second-content{
  top: 30%;
  left: 10%;
  z-index: 1;
}
.second-content2{
  top: 50%;
  margin: auto;
  z-index: 1;
  width: 70%;
  left: 15%;
}
.truck{
  position: absolute;
  left: -111px;
  z-index: 1;
  top: -6%;
  width: 211%;
}
.textame{
  width: 90%;
  min-height: 200px;

}
.prdu-truck2{
  position: absolute;
  bottom: -104px;
  max-width: 150%;
  right: 0;
}
.truck2{
  position: absolute;
  right: -31%;
  z-index: 1;
  top: -53%;
  width: 209%;
}
.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 80px;
  right: 22px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}
.my-float {
  margin-top: 16px;
}


.first-backgound{
width: 100%;

}
.paddig_7{
  padding: 7%;
}
.image1{
z-index: 0;
padding-top: 10%;
}
.image2{
z-index: 1;
right: -5%;
top: -5%;

}
.image2 > img {
width: 88%;
}
.plainCard{
  border: none!important;
  background:none!important;
}
.coloredCard{
  border: none!important;
  background-color:#CEDEE6!important;
}
.facebook{
  margin: 2% 5%;
  width: 2%;
  right: 0;
}


.scroll{
  margin: 2% 5%;
  width: ;
 
}
.Face{
  margin: 0!important;
}
.Banner-heading {
padding: 17%;
margin-left: -45%;
}
.second-image{
 top: 10%;
}
.white{
  color: white;
}
.heading1{
  color: white;
  font-size: 3vw;
}
.heading-abt1{
  color: white;
  font-size: 3vw;
}
.headingport1{
  color: white;
  font-size: 3vw;
}
.prdu-truck{
  position: absolute;
  bottom: -104px;
  max-width: 75%;
  right: 0;
}
.proc-img{
  width: 100%;
}
input{
  padding:5px 8px;
}
select{
  padding:5px 8px;
}
.yellow-text{
   color: #FBA91F;
}
.yellow{
  background-color: #FBA91F!important; 
  border-color:#FBA91F!important;  
}
.white-back{
  background-color: white;
}
.heading2{

  padding: 1px;
  font-size: 3vw;
  background-color: white;
  color: rgb(20, 102, 151);
}
.heading-abt2{

  padding: 1px;
  font-size: 3vw;
  background-color: white;
  color: rgb(20, 102, 151);
}
.headingport2{
  padding: 1px;
  font-size: 3vw;
  background-color: white;
  color: rgb(20, 102, 151);
}

.heading3{
  color: white;
  font-size: 3vw;


}
.headingport3{
  color: white;
  font-size: 3vw;
}
.watsapp{
  width: 20rem;
  color:#25d366;
  border-radius: 50px;
  text-align: center;

  z-index: 100;
}
.watsapp i{
  width: 100%;
  font-size: 10rem;
}
a{
  color: black;
}
.nav-item > a{
  text-align: center;
}
.nav-pills .nav-link.active{
  background-color: #FBA91F!important;
  padding: 10px;
  color: #FBA91F;
  text-align: center;
}

.nav-link{
margin: 5px;
    padding: 10px;
}
.para-heading{
  padding: 30px 0 0 0;


}
.white-text{
  color:#ffff;
}
.para-heading1{
  padding: 30px 0 0 0;
 
}

.box-shadow{
  box-shadow: 10px 10px 5px #aaaaaa;
}
.first-image{
  width: 50%;
}
.about-img{
  width: 75%;
}
.second-two{
  top: -30%;
  left: 45%;
  width: 49%;
}
.back-blue{
  background-color: #146697!important;
  padding:20px!important;
}
.padd-up-down-10{
  padding: 20px 10px;

}

.padding-30{
  padding-top: 30%;
}
.padding-20{
  padding-top: 15%;
}
.no-gap{
  padding:0!important;
  margin: 0!important;
}
.margin-zero{
  margin: 0!important;
}
.padding-50{
  padding-top: 20%;
}
.para-heading::after{
  position: absolute;
  height: 2px;
  width: 50px;
  content: "";
  background: #f7b422;
  left: 0%;
  top: 108%;
  border: 2px solid orange;
}
.padding-15{
  padding-right:15%;
}
.w-60{
 width: 60%;

}
.btn-primary {
  background-color:#146697;
  border-color:#146697;
}
.carousel-item{
  text-align: center;
}
.para-heading1::after{
  position: absolute;
  height: 2px;
  width: 50px;
  content: "";
  background: #f7b422;
  left: 36%;
  top: 65%;
  border: 2px solid #ffff;
}
.footer-ul{
  padding-inline-start: 0!important;
}
.padd-up-down{
  padding: 100px 0;
}
.padd-up-down-no{
  padding: 100px 0;
}
.image-div > img{
  width: 52%;
  padding: 7%;
}
.five{
  padding:25px;
  text-align: left;
} 
.card-body{
  text-align: left;
  height: 230px;
}
.image-div{
  text-align: center;
  height: 110px;
}
.para-heading2{
padding-top:10%;

}
.padding-10{
  padding: 10px 10px;
}
.para{
  padding-top:5%;
  color: gray;
  line-height: 1.8;
}
.container-fluid{
  padding: 0%!important;
  margin: 0%!important;
}
.Banner-heading{
  left: -20px;
}
.text-content{
  background-color: #ECF1F7;
  padding: 10px;
  top: 30%;
  left: 40%;
  z-index: 1;
}

.padding-bootom{
  padding-bottom: 5%;
}
.padding-top{
  padding-top: 5%;
}
.padding-top-10{
padding-top: 10%;
}
.padding-100{
  padding-top: 100px;
}
.Font-heading{
  font-size: 5vw;
  font-weight: 500;
  color: #FBA91F;
}
.Font-heading2{
  font-size: 18px;
}
.Font-para{
  font-size: 14px;
}
.padding-top-20{
  padding-top: 20px;
}
.padding-top-20{
  padding-top: 260px;
}
.margin-top-20{
  margin-top: 260px;
}
.padding-top-not-20{
  margin-top: -125px;
}
.yellow-back{
  background-color: #f7b422;
}

.progressbar {
  background-color:grey;
  border-radius: 13px;
 
}
.port-fav{
  left: 0;
}
.one-size{

  padding-bottom: 20px;
}

.port-heading > h1{
 text-align: center;
}
.progressbar > div {
  background-color: orange;
  height: 10px;
  margin: 10px 0;
  border-radius: 10px;
}
#probar1{
  width: 99%;
}
#probar2{
  width: 98%;
}
#probar3{
  width: 99%;
}
#probar4{
  width: 97%;
}
.padding-side{
  padding: 0 100px;
}
.margin-top-10{
  margin-top: 10%;
}
.padding-bottom-20{
  padding-bottom: 20%;
}

.product--price .product--price_price {
  color: rgba(20, 180, 180, 0.9);
  font-size: 1.2em;
  padding-right: 10px;
}
.product--price .product--price_offer {
  color: indianred;
  border: 1px solid #952e2e;
  border-radius: 5px 5px;
  padding: 1px 3px;
  font-size: .7em;
}

.product--desc {
  font-family: sans;
}
.product--desc p {
  margin: 20px 0px;
}

.product--size {
  margin: 20px 0;
}
.product--size span {
  display: inline-block;
  width: 35px;
  border: 1px solid #999;
  border-radius: 4px 4px;
  text-align: center;
  padding: 1px 2px;
  text-transform: uppercase;
  cursor: pointer;
}

.product--size span.active {
  color: #fff;
  background: #008080;
}

.product--quantity input {
  border: 0;
  outline: 0;
  border-left: 1px dotted;
  border-right: 1px dotted;
  border-bottom: 1px dashed;
  padding: 1px 3px;
  text-align: center;
}
.product--quantity input::-webkit-inner-spin-button, .product--quantity input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.add-to-cart {
  margin: 10px 10px 10px 0;
}

h1,h3,h5{
  font-weight: 800!important;
}
.four{
padding: 14px 5px!important;
}


.padding-20-one{
padding-top: 20%;
}
ul#footer-address {
  list-style: none;
  padding: 0;
  margin-left: 25px;
}
ul#footer-address li {
  padding-left: 1.3em;
}
ul#footer-address li:before {
  font-family: Font Awesome 5 Free;
  display: inline-block;
  margin-left: -1.3em; /* same as padding-left set on li */
  width: 1.3em; /* same as padding-left set on li */
}
ul#footer-address li#map-icon:before {
  content: '\f3c5';
  color:white;
}
ul#footer-address li#phone-icon:before {
  content: '\f879';
  color:white;
}
ul#footer-address li#envelope-icon:before {
  content: '\f0e0';
  color:white;
}



#footer{
  background-color: #1C559F;
  padding: 50px 0 0 0;
  color: white;
  clear: both;
  }
footer {

  text-align: center;
  background-color: #123E85;
  width: 100%;
 
  clear: both;
}
.papad{
  padding:10px;
  padding-left:20px;
}
.carousel-control-next, .carousel-control-prev{
  visibility: hidden!important;
}
.circle-container {
  position: relative;
  width: 24em;
  height: 24em;
  padding: 2.8em;
  /*2.8em = 2em*1.4 (2em = half the width of a link with img, 1.4 = sqrt(2))*/
  border: solid 5px #146697;
  border-bottom: none;
  border-radius: 50%;
  margin: 1.75em auto 0;
}
.carousel-indicators li{
  visibility: hidden!important;
}
.circle-container{
  clear:both;
}
.single-attr {
  list-style: none;
}
.proback{
background-color:#CEDEE6;
}
.single-attr li::before {
  content: "\2022";
  color:#f7b422;
  font-weight: bold;
  display: inline-block; 
  width: 1em;
  margin-left: -1em;
  font-size: 1.5rem;
}

.single-attr > li{
font-size: 1.2rem;
padding: 3%;
}
.circle-container div {
  display: block;
  position: absolute;
  top: 40%;
  left: 10%;
  width: 22rem;
  height: 4rem;
  margin: -2rem;
}
.circle-container span { display: block; width: 100%; }


footer p {
  color: #ccc;
}
.color-yellow{
  color:#FBA91F;
}
.quick-links >  li > a {
  color: #ccc;
}
.display-flex{
  display: flex;
}
.display-flex > i{
  padding-right: 8px;
  padding-top: 2px;
}
.padd-up-down-per{
  padding: 100px 0;
}
.padd-up-down-abt{
   padding: 165px 0;
}

@media screen and (max-width: 500px) {
  .product--image, .product--details {
    float: none;
    width: 100%;
    padding: 10px 5px;
    margin: 5px 10px;
  }
  .prdu-truck {
display: none;


}
.prdu-truck2 {
  display: none;
  
  
  }
.padd-up-down-no{
  padding:  0;
}
.padd-up-down-abt{
   padding: 20px 0;
}
.padd-up-down-per{
  padding: 50px 0;
}
.single-attr{
  margin: 20% 10%;
}

.heading-abt1,.heading-abt2{
  font-size: 1.1rem;
}
.headingport1,.headingport2, .headingport3{
  font-size: 1.5rem;
}
.port-abt{
  margin-top: 20%;
}
.padd-up-down-10{
      padding: 30px 10px;
}
 #mapDiv {
    height: 100%;
    margin: 0;
    padding: 0;
}
}
@media screen and (max-width: 767px) {
  .padding-top-not-20{
    margin-top: 150px;
  }
  .padding-bottom-not-20{
    margin-bottom: 110px;
  }
  .padding-20-one{
    padding-top: 0%;
    }
    .small-height{
      margin-top: 250px!important;
    }
    .small{
      margin-top: 200px!important;
    }
    .truck {
      position: absolute;
      left: -4px;
      z-index: 1;
      top: 2%;
      width: 138%;
  }
}
